import React from 'react';
import { Link } from "gatsby";

const Services = () => {
  return (
    <div id="services" className="bg-white py-24 px-6 text-center">
      <h3 className="section-header">Services Offered</h3>

      <div className="mt-12 lg:flex justify-between items-stretch">
        <div className="w-full mb-10 lg:mb-0 px-4 lg:px-8 lg:flex lg:flex-col">
          <p className="text-gray-700 lg:flex-grow">
            Services offered include water heaters, boilers, water treatments,
            sewer lines, water line replacements, and any general home plumbing
            maintenance.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Services;