import React from "react";
import { Link } from "gatsby";
import closeIcon from "../images/icons/close.svg"

class ContactModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formTermsChecked: true
    }
  }

  render() {
    const props = this.props;

    if(props.displayThanks) {
      return (
        <div>
          <div onClick={props.hideModal} className="z-20 h-full w-full fixed" style={{ backgroundColor: "rgba(0,0,0,.7)", top: 0, left: 0 }}></div>

          <div className="overflow-y-scroll z-30 fixed h-full w-full top-0 left-0" onClick={props.hideModal}>
            <div className="modal" onClick={e => e.stopPropagation()}>
              <div className="modal__body">
                <img src={closeIcon} className="modal__close h-4 cursor-pointer" onClick={props.hideModal} />
                <h3 className="modal__header">Thanks for contacting us!</h3>
                <p className="modal__lead">Thanks so much for contacting us. We will be in touch with you within 48 hours.</p>
                <button onClick={() => typeof window !== 'undefined' && (window.location = window.location.href.replace("?form-success=true", ""))} className="cursor-pointer mt-6 bg-maroon-lighter hover:bg-maroon text-white rounded-sm py-2 px-6">Close window</button>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <div
            onClick={props.hideModal}
            className="z-20 h-full w-full fixed"
            style={{ backgroundColor: 'rgba(0,0,0,.7)', top: 0, left: 0 }}
          ></div>

          <div
            className="overflow-y-scroll z-30 fixed h-full w-full top-0 left-0"
            onClick={props.hideModal}
          >
            <div className="modal" onClick={(e) => e.stopPropagation()}>
              <div className="modal__body">
                <img
                  src={closeIcon}
                  className="modal__close h-4 cursor-pointer"
                  onClick={props.hideModal}
                />
                <h3 className="modal__header">Schedule Service</h3>
                <p className="modal__lead">
                  Need plumbing services for your home? Fill out the following
                  form and we'll contact you to schedule your appointment.
                </p>

                <form
                  className="mt-8"
                  id="request-quote-modal-form"
                  name="request-quote-modal-form"
                  method="POST"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  action={
                    typeof window !== 'undefined' &&
                    window.location.href.concat('?form-success=true')
                  }
                >
                  <div className="mb-4">
                    <label
                      htmlFor="name"
                      className="font-semibold text-gray-700 text-sm"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      className="w-full bg-gray-200 p-4 rounded"
                      name="name"
                      required
                    />
                  </div>

                  <div className="mb-4">
                    <label
                      htmlFor="email"
                      className="font-semibold text-gray-700 text-sm"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      id="contact-email"
                      className="w-full bg-gray-200 p-4 rounded"
                      name="email"
                      required
                    />
                  </div>

                  <div className="mb-4">
                    <label
                      htmlFor="phone"
                      className="font-semibold text-gray-700 text-sm"
                    >
                      Phone
                    </label>
                    <input
                      type="text"
                      id="contact-phone"
                      className="w-full bg-gray-200 p-4 rounded"
                      name="phone"
                    />
                  </div>

                  <div className="mb-4">
                    <label
                      htmlFor="location"
                      className="font-semibold text-gray-700 text-sm"
                    >
                      Location
                    </label>
                    <input
                      type="text"
                      id="contact-location"
                      className="w-full bg-gray-200 p-4 rounded"
                      name="project_location"
                      required
                    />
                  </div>

                  <div className="mb-4">
                    <label
                      htmlFor="details"
                      className="font-semibold text-gray-700 text-sm"
                    >
                      Details
                    </label>
                    <textarea
                      className="w-full bg-gray-200 p-4 rounded"
                      rows="5"
                      id="contact-details"
                      name="project_details"
                      required
                    />
                  </div>

                  <div className="mb-3 text-gray-800 text-sm max-w-md">
                    <input
                      type="checkbox"
                      name="contact_terms"
                      id="contact-terms"
                      checked={this.state.formTermsChecked}
                      onChange={(e) =>
                        this.setState({formTermsChecked: e.target.checked})
                      }
                      className="mr-2"
                    />
                    <label htmlFor="contact-terms">
                      By submitting the information in this form, I consent to
                      receive service information and marketing offers by calls,
                      text, or email via any telephone numbers / email addresses I
                      provide.
                    </label>
                  </div>

                  <input
                    type="hidden"
                    name="form-name"
                    id="form-name"
                    value="request-quote-modal-form"
                  />
                  <input type="hidden" name="bot-field" id="bot-field" />

                  <input
                    type="submit"
                    className="cursor-pointer bg-blue-800 hover:bg-blue-900 text-white rounded-sm py-4 px-12"
                    value="Send message"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
}

export default ContactModal;