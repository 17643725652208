import React from "react";
import SmallGoogleMap from "./small-google-map";

class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formTermsChecked: true
    }
  }

  render() {
    return (
      <div id="contact">
        <div className="bg-white py-24 px-6 md:flex">
          <div className="w-full md:w-2/3 mr-12">
            <h3 className="text-xl font-semibold text-gray-800 mb-4">
              Contact Tracey Plumbing
            </h3>

            <form
              className="w-full mb-12 lg:mb-0 mt-8"
              id="generic-footer-form"
              name="generic-footer-form"
              method="POST"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              action={
                typeof window !== 'undefined' &&
                window.location.href.concat('?form-success=true')
              }
            >
              <div className="mb-3">
                <input
                  type="text"
                  className="bg-gray-200 text-gray-800 w-full max-w-md rounded-sm p-4"
                  placeholder="Name"
                  name="name"
                  required
                />
              </div>
              <div className="mb-3">
                <input
                  type="email"
                  className="bg-gray-200 text-gray-800 w-full max-w-md rounded-sm p-4"
                  placeholder="Email"
                  name="email"
                  required
                />
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  className="bg-gray-200 text-gray-800 w-full max-w-md rounded-sm p-4"
                  placeholder="Phone number"
                  name="phone_number"
                />
              </div>
              <div className="mb-3">
                <textarea
                  className="bg-gray-200 text-gray-800 rounded-sm p-4 w-full max-w-xl"
                  name="message"
                  placeholder="Message"
                  rows="4"
                  required
                />
              </div>

              <div className="mb-3 text-gray-800 text-sm max-w-md">
                <input
                  type="checkbox"
                  name="terms"
                  id="terms"
                  checked={this.state.formTermsChecked}
                  onChange={(e) =>
                    this.setState({ formTermsChecked: e.target.checked })
                  }
                  className="mr-2"
                />
                <label htmlFor="terms">
                  By submitting the information in this form, I consent to
                  receive service information and marketing offers by calls,
                  text, or email via any telephone numbers / email addresses I
                  provide.
                </label>
              </div>

              <input
                type="hidden"
                name="form-name"
                id="form-name"
                value="generic-footer-form"
              />
              <input type="hidden" name="bot-field" id="bot-field" />

              <input
                type="submit"
                className="cursor-pointer bg-blue-800 hover:bg-blue-800 text-white rounded-sm py-4 px-12 shadow"
                value="Send message"
              />
            </form>
          </div>
          <div className="w-full md:w-1/3">
            <h3 className="text-xl font-semibold text-gray-800 mb-4">
              Contact Information
            </h3>
            <p className="text-gray-800 mt-4">
              <span className="text-gray-500 font-semibold">Phone:</span>{' '}
              <a href="tel:4106954340">410-695-4340</a>
            </p>
            <p className="text-gray-800 mt-4">
              <span className="text-gray-500 font-semibold">Email:</span>{' '}
              <a href="mailto:traceyplumbingmd@gmail.com">
                traceyplumbingmd@gmail.com
              </a>
            </p>

            <div className="mt-12">
              <h3 className="text-xl font-semibold text-gray-800 mb-4">
                Service Area
              </h3>
              <p className="text-gray-800 mt-4">
                Anne Arundel County, Baltimore County, Baltimore City, Carroll
                County, Cecil County, Harford County, Howard County
              </p>
            </div>
          </div>
        </div>

        <div className="bg-gray-200 py-12 px-6 md:flex text-gray-900">
          <div className="md:w-1/2">
            <p>
              <span className="font-bold">Tracey Plumbing, LLC</span>
              <br />
              <span className="text-gray-500">
                &copy; Copyright 2023, All rights reserved
              </span>
            </p>
          </div>

          <div className="mt-8 md:mt-0 md:w-1/2 md:text-right">
            <p className="text-gray-700">
              <span className="text-xs uppercase">Website & marketing by</span>
              <br />
              <span className="text-gray-500 hover:text-white cursor-pointer">
                <span className="font-bold">Riptide</span>Growth
              </span>
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default Footer;