import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql, Link } from "gatsby";
import Navbar from "./navbar";
import Hero from "./hero"

import ContactModal from "./contact-modal";
import HiddenFormForNetlify from "./hidden-form-for-netlify";
import Footer from "./footer";
import Services from "./services";
import About from "./about";

class Layout extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      displayContactModal: false,
      displayThanks: typeof window !== 'undefined' && new URLSearchParams(window.location.search).get("form-success")
    }
  }

  displayModal() {
    this.setState({displayContactModal: true})
  }

  hideModal() {
    this.setState({displayContactModal: false})
  }

  render() {
    const modal = (this.state.displayContactModal || this.state.displayThanks) ? <ContactModal hideModal={this.hideModal.bind(this)} displayThanks={this.state.displayThanks} /> : <HiddenFormForNetlify /> ;

    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <div>

              {this.props.children}

              <Navbar displayModal={this.displayModal.bind(this)} />
              <Hero displayModal={this.displayModal.bind(this)} />
              <Services />
              <About />
              <Footer />

              {modal}
          </div>
        )}
      />
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
