import React from 'react';
import { Link } from "gatsby";
import allenHeadshot from "../images/allen-headshot.png"

const About = () => {
  return (
    <div id="about" className="bg-gray-100 py-24 px-6 text-left">
      <div className="lg:flex justify-center items-center lg:max-w-5xl lg:mx-auto">
        <div className="mx-auto lg:mx-0 lg:mb-0 mb-12 rounded-full h-64 w-64 overflow-hidden border-gray-300 border-8 block">
          <img
            src={allenHeadshot}
            alt="allen tracey master plumber"
            className="max-w-full"
          />
        </div>
        <div className="lg:w-2/3 lg:ml-24 w-full">
          <h5 className="section-header">
            About Allen Tracey | <span class="font-thin">Master Plumber</span>
          </h5>
          <p className="text-gray-700 lg:flex-grow mt-2">
            Allen Tracey III is the proud owner of Tracey Plumbing. Allen is an
            experienced licensed master plumber and gas fitter who prides
            himself on delivering honest, friendly and reliable service to all
            customers. Tracey Plumbing is located in Harford County, Maryland
            where Allen grew up and currently resides but is proud to offer
            service to the entire greater Baltimore area.
          </p>
        </div>
      </div>
    </div>
  )
}

export default About;