import React from "react";
import heroImg from "../images/hero-background-2.jpg";

const Hero = (props) => {
  return (
    <div
      className="pt-32 pb-48 px-6"
      style={{
        backgroundImage: `url('${heroImg}')`,
        backgroundSize: 'cover',
        backgroundPosition: 'top center',
      }}
    >
      <div className="max-w-2xl hero-content lg:p-12 md:p-8 p-6 mx-auto mt-8 bg-white mb-24 text-center rounded">
        <h1 className="text-black text-3xl font-bold mb-4">
          Experienced master plumber in the greater Baltimore area
        </h1>
        <p className="text-gray-800 font-sm">
          Tracey Plumbing is a premier plumbing business servicing the greater
          Baltimore area. Owned by Allen Tracey III, an experienced licensed
          master plumber and gas fitter, Tracey Plumbing provides honest,
          friendly and reliable service you can trust. From plumbing emergencies
          to routine maintenance and renovations, Tracey Plumbing is committed
          to providing outstanding quality service.
        </p>

        <div className="mt-10">
          <button
            onClick={props.displayModal}
            className="w-full md:w-auto cursor-pointer bg-blue-800 hover:bg-blue-900 text-white px-8 py-4 rounded-sm shadow"
          >
            Schedule Service Now!
          </button>
        </div>
      </div>
    </div>
  )
}

export default Hero;